/**
 * 佣金管理-佣金信息-查看
 * luxinwen
 * 2023-03-22
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="900" :before-close="beforeClose">
      <div class="page-main-content">
        <div class="page-main-content-thead">佣金发放状态：{{ statusList[formData.commissionStatus] }}</div>
        <div class="page-main-content-tbody">
          <Row>
            <Col span="8">客户姓名：{{ formData.customerName }}</Col>
            <Col span="8">性别：{{ genderList[formData.gender] }}</Col>
            <Col span="8">电话：{{ formData.phone }}</Col>
            <Col span="12">项目名称：{{ formData.buildingName }}</Col>
            <Col span="12">活动名称：{{ formData.activityName }}</Col>
            <Col span="12">推荐时间：{{ formData.recommendTime }}</Col>
            <Col span="12">佣金总额：{{ formData.commissionAmount }}</Col>
          </Row>
        </div>
      </div>
      <Divider />
      <Table :columns="tableColumns" :data="formData.operateInfoList" border>
        <template slot-scope="params" slot="commissionStatus">
          <span>{{ statusList[params.row.commissionStatus] }}</span>
        </template>
      </Table>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { COMMISSION_STATUS, GENDER } from '@/util/enum';
  const formData = {
    activityName: '', // 活动名称
    buildingName: '', // 项目名称
    commissionAmount: null, // 佣金金额
    commissionStatus: null, // 佣金发放状态：0待发放/1发放中/2已发放/3发放异常
    customerName: '', // 客户姓名
    gender: null, // 客户性别：1男0女
    phone: '',  // 客户手机号
    recommendTime: '', // 推荐时间
    operateInfoList: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {},
        backupData: {},
        tableColumns: [
          {
            title: '状态',
            slot: 'commissionStatus'
          },
          {
            title: '操作人',
            key: 'operateLoginName'
          },
          {
            title: '操作时间',
            key: 'operateTime',
            render: (h, params) => {
              return h('span', this.formatDate(params.row.operateTime));
            }
          },
          {
            title: '备注信息',
            key: 'remark'
          }
        ],
        statusList: COMMISSION_STATUS,
        genderList: GENDER
      };
    },
    computed: {
      title() {
        return '佣金信息';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        let isInit = !this.data.id;
        this.initData(this.data, isInit);
        if (isInit) return;
        this.$axios({
          url: this.$api.member.queryCommissionDetail,
          data: {
            customerId: this.data.id
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let json = Object.assign({}, data.customerInfo || {}, {
            operateInfoList: data.operateInfoList
          });
          this.initData(json, true);
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>