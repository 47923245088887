/**
 * 客户管理-客户信息
 * luxinwen
 * 2023-04-23
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_CUSTOMER_MGM_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.customerName" placeholder="请输入客户名称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.phone" placeholder="请输入手机号" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.memberNickname" placeholder="请输入经纪人" clearable />
          </FormItem>
          <FormItem>
            <DatePicker class="width-m" v-model="formData.recommendTime" type="datetimerange" placeholder="请选择推荐时间" />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.buildingName" placeholder="请输入项目名称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.activityName" placeholder="请输入活动名称" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择状态" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="Number(key)">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="status">
            <span>{{ statusList[params.row.status] }}</span>
          </template>
          <!-- <template slot-scope="params" slot="time">
            <span>{{ params.row.startRecommendTime }} - {{ params.row.endRecommendTime }}</span>
          </template> -->
          <template slot-scope="params" slot="operation">
            <!-- <Button @click="showData(params.row)" v-grant="'OSP_CUSTOMER_MGM_VIEW'">查看</Button> -->
            <Button @click="changeStatus(params.row)" v-show="params.row.status === 1" v-grant="'OSP_CUSTOMER_MGM_VISIT'">到访</Button>
            <Button @click="changeStatus(params.row)" v-show="params.row.status === 2" v-grant="'OSP_CUSTOMER_MGM_TOBUY'">认购</Button>
            <Button @click="changeStatus(params.row)" v-show="params.row.status === 3" v-grant="'OSP_CUSTOMER_MGM_DEAL'">成交</Button>
            <Button @click="showCommission(params.row)" v-show="params.row.status === 4" v-grant="'OSP_CUSTOMER_MGM_FINAL'">佣金信息</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <commission-info v-model="commissionDisplay" :data="commissionData"></commission-info>
  </div>
</template>

<script>
  import { ACTIVITY_PART_STATUS } from '@/util/enum';
  import editDrawer from './edit.vue';
  import commissionInfo from '@/views/commission/mgm/info.vue';

  export default {
    components: {
      editDrawer,
      commissionInfo
    },
    data() {
      return {
        formData: {
          customerName: '', // 客户姓名
          activityName: '', // 活动名称
          buildingName: '', // 项目名称
          status: null, // 推荐状态：0已失效，1已推荐，2已来访，3已认购，4已成交
          phone: '',  // 客户手机号
          memberNickname: '', // 经纪人
          recommendTime: [] // 推荐时间
        },
        tableColumns: [
          {
            title: '客户名称',
            key: 'customerName'
          },
          {
            title: '客户手机号',
            key: 'phone'
          },
          {
            title: '经纪人',
            key: 'memberNickname'
          },
          {
            title: '推荐时间',
            key: 'recommendTime'
          },
          {
            title: '楼盘项目',
            key: 'buildingName'
          },
          {
            title: '活动名称',
            key: 'activityName'
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        commissionDisplay: false,
        commissionData: {},
        statusList: ACTIVITY_PART_STATUS
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_CUSTOMER_MGM_VISIT, this.$grant.OSP_CUSTOMER_MGM_TOBUY, this.$grant.OSP_CUSTOMER_MGM_DEAL, this.$grant.OSP_CUSTOMER_MGM_FINAL];  // this.$grant.OSP_CUSTOMER_MGM_VIEW,
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (this.formData.recommendTime.length > 1) {
          data.startRecommendTime = this.formatDate(this.formData.recommendTime[0]);
          data.endRecommendTime = this.formatDate(this.formData.recommendTime[1]);
        }
        delete data.recommendTime;
        this.$axios({
          url: this.$api.customer.queryCustomerList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看
       */
      showData(row) {
        this.drawerFlag = 'show';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 佣金信息
       * @param  {[type]} row [description]
       * @return {[type]}     [description]
       */
      showCommission(row) {
        this.commissionData = {
          id: row.id
        };
        this.commissionDisplay = true;
      },
      /**
       * 状态调整
       */
      changeStatus(row) {
        let txt = this.statusList[row.status + 1];
        this.$confirm(`确定要将客户状态调整为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.customer.changeCustomerStatus,
            data: {
              id: row.id,
              status: row.status + 1
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '操作成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>